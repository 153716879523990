var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({class:{
    's-text': true,
    'w-normal': _vm.weight === 'normal',
    'w-medium': _vm.weight === 'medium',
    'w-bold': _vm.weight === 'bold',
    'text-xm': _vm.size === 'xm',
    'text-sm': _vm.size === 'sm',
    'text-md': _vm.size === 'md',
    'text-md-m': _vm.size === 'md-m',
    'text-lg': _vm.size === 'lg',
    'text-xlg': _vm.size === 'x-lg',
    'text-mlg': _vm.size === 'm-lg',
  },style:({
    color: `var(--v-${_vm.color}-base)`,
    fontSize: `$text-${_vm.size}`,
  })},'div',_vm.$attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
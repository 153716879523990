<template>
  <div
    :class="{
      's-text': true,
      'w-normal': weight === 'normal',
      'w-medium': weight === 'medium',
      'w-bold': weight === 'bold',
      'text-xm': size === 'xm',
      'text-sm': size === 'sm',
      'text-md': size === 'md',
      'text-md-m': size === 'md-m',
      'text-lg': size === 'lg',
      'text-xlg': size === 'x-lg',
      'text-mlg': size === 'm-lg',
    }"
    :style="{
      color: `var(--v-${color}-base)`,
      fontSize: `$text-${size}`,
    }"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SText',
  props: {
    weight: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'black',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.s-text {
  cursor: default;
}
.w-normal {
  font-weight: $font-normal;
}

.w-medium {
  font-weight: $font-medium;
}

.w-bold {
  font-weight: $font-bold;
}

.text-xm {
  font-size: $text-xm;
}

.text-sm {
  font-size: $text-sm;
}

.text-md {
  font-size: $text-md;
}

.text-md-m {
  font-size: $text-md-m;
}

.text-lg {
  font-size: $text-lg;
}

.text-xlg {
  font-size: $text-xlg;
}

.text-mlg {
  font-size: $text-mlg;
}
</style>
